:root{
  --primary-color: #5201FF;
  --font-color:#1A1323;
  --font-color-1: #1A1323;
  --font-light-color: #9A9A9A;
  --border-color:#e9e9e9;
  --border-color-1: rgba(217, 217, 217, 0.5);
  --background-color:#EEEEF4;
  --background-color-1: #FFFFFF;
  --background-color-2: #F9F9F9;
  --modal-background-color: rgba(244, 244, 248, 0.9);
  --sns-font-color: #534e59;
  --bs-border-color_opacity: rgba(233, 235, 237, 0.6);
  --bs-body-color_active:#1A1323;
  --border-color-2:#7E7B88;
  --proposal-border:#29282F;
  --proposal-tag-border: #29282F;
  --line-home:#E9EBED;
}
